@tailwind base;
@tailwind components;
@tailwind utilities;
html,body{
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    background-color: #0C0C0D;
}
.box_transparent {
    position: relative;
    display: flex;
    flex-direction: row;
}

.box_transparent::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1.5px;
    /*control the border thickness */
    background: linear-gradient(to bottom, transparent, rgba(31, 248, 209, 1));
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}